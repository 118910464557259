import { ITranslationService, I18NextLoadResult, I18NextModule } from 'angular-i18next';
import HttpApi from "i18next-http-backend";
import { ConfigService } from './configuration/config.service';
import { LocalizationUrlService } from './services/data/localization-url.service';
import { LocalizationRequester } from './models/localization-requester';

export function appInit(configService: ConfigService, i18next: ITranslationService, localizationUrlService: LocalizationUrlService) {
  return async () => {
    console.log("Init i18Next");
    let result = await configService.loadConfigFile();
    if (result) {
      let promise: Promise<I18NextLoadResult> = i18next.use(HttpApi)
        .init({
          fallbackLng: configService.Config.defaultCulture,
          lng: configService.Config.defaultCulture,
          debug: true,
          returnEmptyString: false,
          ns: [
            'WebProfile'
          ],
          defaultNS: 'WebProfile',
          fallbackNS: 'WebProfile',
          backend: {
            loadPath: localizationUrlService.getResourcesUrl(LocalizationRequester.WebAlertAnswer, configService, null),
            allowMultiLoading: true
          },
          load: 'currentOnly',
          interpolation: {
            format: I18NextModule.interpolationFormat((value, format, lng) => {
              if (format === 'uppercase') {
                return value.toUpperCase();
              }
              if (format === 'lowercase') {
                return value.toLowerCase();
              }
              return value;
            })
          }
        });
      return promise;
    }
    else {
      return new Promise((resolve, reject) => {
        reject();
      });
    }
  };
}